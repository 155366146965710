import {actions} from 'react-fields'

class SideLayerApi {

    static getLayer(path) {
        let url = `/api/side_layers?path=${encodeURIComponent(path)}`;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then((data) => {
                return data;
            })
    }


}

export default SideLayerApi;