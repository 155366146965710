import React, {useState} from "react";
import Form from '../../subscriptions/forms/templateDownloadButton';
import SubscriptionActionHOC from '../../subscriptions/subscriptionActionHOC';

const SubscriptionForm = SubscriptionActionHOC(Form);

function SubscriptionCv(props) {

    return(
        <div className="">
            <div className="right-side-content">
                <div className={'bg-image'}  />
            </div>
                <div className="left-side-content text-center pad-b-27">
                    <img src={'images/popups/socialSubscription/logo.png'} className={'main-logo'} style={{maxHeight: '13px'}}/>
                    <div className={'blue-cut-off-line'}>
                        Trusted by 10 Million Annual Readers
                    </div>
                    <h2 className="head blue size-48">GRAB OUR <u>FREE</u> RESUME TEMPLATE</h2>
                    <div className="mar-top-10 mar-bot-20" style={{lineHeight: '19px'}}>
                        <div className={'ticked-txt'}>MS Word editable</div>
                        <div className={'ticked-txt'}>Professional design</div>
                        <div className={'ticked-txt'}>Easy installation</div>
                    </div>
                    <SubscriptionForm formType={'popup_cv'} popupId={props.popupId} />
                    <p className={'mar-top-10 mar-bot-30'}>By submitting your email, you agree to receive our daily newsletter.</p>
                    <p className={'size-15 light mar-bot-15'}>Featured on</p>
                    <div className={'grey-on-hover'} style={{maxHeight: '21px'}}>
                        <div className={'logo-block inline'}>
                            <img src={'images/logos/business-insider-color.webp'} style={{maxHeight: '20px'}} alt={'Business Insider'}/>
                        </div>
                        <div className={'logo-block inline'}>
                            <img src={'images/logos/yahoo-finance-color.webp'} style={{maxHeight: '20px'}} alt={'Yahoo Finance'}/>
                        </div>
                        <div className={'logo-block inline'}>
                            <img src={'images/logos/independent-color.webp'} style={{maxHeight: '20px'}} alt={'Independent'}/>
                        </div>

                    </div>
                </div>
        </div>
    );

}
export default SubscriptionCv;