import React, {Component} from "react";
import Slider from "react-slick";

const DEFAULT_SLIDER_SETTINGS = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 600,
    cssEase:  'ease-out',
    slidesToShow: 6,
    slidesToScroll: 1,
    variableWidth: false,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 640,
            settings: {
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                speed: 300,
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 360,
            settings: {
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export default class ImagesListCarousel extends Component {

    prepareSettings = () => {
        let settings = this.props.settings ? {...DEFAULT_SLIDER_SETTINGS, ...this.props.settings} : {...DEFAULT_SLIDER_SETTINGS};

        if (this.props.dynamicSlidesToShow){
            if(settings.slidesToShow > this.props.images.length) {
                settings.slidesToShow = this.props.images.length;
            }
            if(settings.slidesToScroll > this.props.images.length) {
                settings.slidesToScroll = this.props.images.length;
            }
            settings.responsive.map((breakpoint) => {
                if(breakpoint.settings.slidesToShow > this.props.images.length) {
                    breakpoint.settings.slidesToShow = this.props.images.length;
                }
                if(breakpoint.settings.slidesToScroll > this.props.images.length) {
                    breakpoint.settings.slidesToScroll = this.props.images.length;
                }
            })
        }
        return settings;
    };

    state = {
        settings: this.prepareSettings(),
        keyPrefix: Math.random().toString(36).substring(7)
    };

    renderImage = (image, index) => {
        let linkAttrs = {};
        if (!!image.rel) {linkAttrs['rel'] = image.rel;}
        if (image.target_blank) {linkAttrs['target'] = '_blank';}
        let img = image.upload_height && image.upload_width ?   <img src={image.src} alt={image.alt} width={image.upload_width} height={image.upload_height}/> : <img src={image.src} alt={image.alt}/>;
        return (<div key={`${this.state.keyPrefix}${index}`} className={this.props.imgContainerClassName}>
            {image.link ?
                <a href={image.link} {...linkAttrs}>
                    {img}
                </a> :
                img
            }

        </div>)
    };



    render() {

        return(
            <div className='grid-x'>
                <Slider {...this.state.settings}>
                    {this.props.images.map((image, index) => this.renderImage(image, index))}
                </Slider>
            </div>
        )
    }

}