import React, {useState} from "react";
import TwoLineInputSubscription from "../../subscriptions/twoLineInput";
import ReferralContent from "../../subscriptions/referralModalContent";

function PowerhouseSubscription(props) {

    const [isSuccess, setIsSuccess] = useState(false);
    const [giftName, setGiftName] = useState('gift');
    const [referralUrl, setReferralUrl] = useState('');

    const openSuccess = (response) => {
        setIsSuccess(true);
        setGiftName(response['gift_name']);
        setReferralUrl(response['open_url']);
    };

    const onClickReferral = (e) => {
        window.open(referralUrl, '_blank').focus();
        props.close();
    };

    if (isSuccess) {
        return <React.Fragment>
            <style>
                {`.reveal .close-button, .reveal .close-circle:not(.close-referral) {display: none;}`}
            </style>
            <ReferralContent onClick={onClickReferral} giftName={giftName} close={props.close} />
        </React.Fragment>
    }

    return(
        <div>
            <div className="right-side-content">
                <div className="bg-image"/>
            </div>
            <div className="left-side-content text-center pad-b-27">
                <img src="/images/popups/socialSubscription/logo.png" className="main-logo" style={{maxHeight: '13px'}} />
                <div className="blue-cut-off-line">Trusted by 10 Million Annual Readers</div>
                <h2 className="head blue size-48">GRAB OUR <u>FREE</u> RESUME TEMPLATE</h2>
                <div className="mar-top-10 mar-bot-20" style={{lineHeight: '19px'}}>
                    <div className="ticked-txt">MS Word editable</div>
                    <div className="ticked-txt">Professional design</div>
                    <div className="ticked-txt">Easy installation</div>
                </div>
                <TwoLineInputSubscription {...props} onReferralModalOpen={openSuccess} />
                <p className="size-15 light mar-bot-15">Featured on</p>
                <div className="grey-on-hover" style={{maxHeight: '21px'}}>
                    <div className="logo-block inline"><img src="/images/logos/business-insider-color.webp"
                                                            alt="Business Insider" style={{maxHeight: '20px'}}/></div>
                    <div className="logo-block inline"><img src="/images/logos/yahoo-finance-color.webp"
                                                            alt="Yahoo Finance" style={{maxHeight: '20px'}}/></div>
                    <div className="logo-block inline"><img src="/images/logos/independent-color.webp" alt="Independent"
                                                            style={{maxHeight: '20px'}}/></div>
                </div>
            </div>
        </div>

);

}
export default PowerhouseSubscription;