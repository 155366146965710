import React from "react";
import {InputTypeField} from "react-fields";
import SubscriptionActionHOC from "./subscriptionActionHOC";

const SubscriptionForm = SubscriptionActionHOC(props => {
    let divClasses = 'subscribe-input-with-button';
    let btnText = props.formAttrs.btnText || (props.formAttrs.hasGift ? 'DOWNLOAD NOW' : 'SIGN UP FOR FREE');
    let subText = props.formAttrs.subText || (props.formAttrs.hasGift ? 'By submitting your email, you agree to receive our daily newsletter.' : 'Sent 3 times per week. Unsubscribe whenever you want.');
    return (
        <div className={divClasses}>
            <InputTypeField type='email' name="email" value={props.form.email} errorMessage={props.form.errors.email} className={'input-with-container brd-radius-25 text-center'}
                            onChange={props.setEmail}
                            onKeyPress={props.submitOnEnter}
                            placeholder="Your email" />
            <button className="button-orange newsletter-article-sub width-100" onClick={props.submitForm}>{btnText} <span className="small-icon arrow-white-right-small"></span></button>
            <p className={'mar-top-10 mar-bot-30'} dangerouslySetInnerHTML={{__html: subText}} />
        </div>
    )
});

export default function TwoLineInputSubscription(props) {
    return <SubscriptionForm {...props} />
}
