import React from "react";
import Form from '../../subscriptions/form';
import SubscriptionActionHOC from '../../subscriptions/subscriptionActionHOC';
import SubscriptionCv from "../show";

const SubscriptionForm = SubscriptionActionHOC(Form);

function Newsletter(props) {

    return(
          <SubscriptionForm modalName={'subscriptionReveal'} formType={'popup'} popupId={props.popupId} />
    );

}
export default Newsletter;