import React from "react";
import {render as reactDomRender} from "react-dom"
import TopNotificationsApi from "../api/topNotificationsApi";
import cookies from "../cookies";
import SubscribeButton from "../subscriptions/handler";
import {setCookie} from "../base/cookieHelper";
function Layer(props) {

    const icon = props.layer.icon || 'mail';

    return(
        <React.Fragment>
            <div className={'top-promo__wrapper'}>
                <div className={`top-promo__${icon}`}/>
                <p className="top-promo__text" >
                    <span dangerouslySetInnerHTML={{__html: props.layer.left_text}}/>
                        <span className="top-promo__vertical-line show-for-medium"/>
                    {props.layer.subscribe ?
                        <SubscribeButton design={'top_notification'} text={props.layer.right_text} formType={'top_notification'}/>
                        :
                        <a href={props.layer.link} className="top-promo__link"
                           target={props.layer.target_blank ? '_blank' : ''} rel={props.layer.rel}>
                            <span dangerouslySetInnerHTML={{__html: props.layer.right_text}}/>
                            <span className="top-promo__arrow"/>
                        </a>
                    }
                </p>
            </div>
            <div className="top-promo__close" onClick={props.onClose}/>
        </React.Fragment>
    )

}

class LayerPortal extends React.Component {

    state = {
        layer: null
    };

    loadLayers = () => {
        let path = window.location.pathname;
        let _this = this;
        TopNotificationsApi.getLayers(path).then((response) => {
            if (response.top_notification) {
                _this.setState({layer: response.top_notification}, () => {
                    if(response.top_notification && response.top_notification.subscribe && this.isSubscribeCookie()){
                        _this.closeLayer();
                        return;
                    }
                    _this.renderLayer();
                });
            }
        }).catch(error => {
            console.log(error);
        });
    };

    isSubscribeCookie = () => {
        let ca_sub = cookies.getCookieValue('ca_sub');
        return ca_sub && ca_sub === 'true'
    };


    componentDidMount() {
        this.loadLayers();
    }

    componentWillUnmount() {
        if (this.portalElement)
            document.getElementById('topBar').removeChild(this.portalElement);
    }

    renderLayer = () => {
        if(!this.state.layer)
           return null;
        const classNames = `top-promo top-promo--${(this.state.layer.color || 'light-blue')}`;
        let p = document.createElement('div');
        p.className = classNames;
        if (this.state.layer.subscribe){
            p.id = 'top-subscribe';
        }
        this.portalElement = p;
        document.getElementById("top-layer-after").after(p);


        reactDomRender(<Layer onClose={this.closeLayer} layer={this.state.layer}/>, this.portalElement);
    };


    closeLayer = () => {
        setCookie('ca_lay',  {closed: true});
        if (this.portalElement)
            document.getElementById('topBar').removeChild(this.portalElement);
    };


    render () {
        return null
    }
}


export default LayerPortal;
