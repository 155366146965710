export const CURRENCY_COOKIE = 'caCurrency';
export const CURRENCY_DEFAULT_COOKIE = 'caDefaultCurrency';

const DOMAIN_COOKIE = {
    development: 'localhost',
    staging: 'findjobs.eu',
    production: 'careeraddict.com'
}[process.env.RAILS_ENV];

class CookieFunctions {

    static setCookie(name, value, expirationHours) {
        if (expirationHours === undefined) {
            document.cookie = `${name}=${value}; path=/;`;
        } else {
            let expires = new Date();
            expires.setTime(expires.getTime() + (1 * expirationHours * 60 * 60 * 1000));
            document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/;`;
        }
    }

    static setMainCookie(name, value, expirationHours) {
        if (expirationHours === undefined) {
            document.cookie = `${name}=${value}; path=/; domain=${DOMAIN_COOKIE};`;
        } else {
            let expires = new Date();
            expires.setTime(expires.getTime() + (1 * expirationHours * 60 * 60 * 1000));
            document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/; domain=${DOMAIN_COOKIE};`;
        }
    }

    static getCookieValue(cookieName) {
        let name = cookieName + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static deleteCookie( name ) {
        document.cookie = name+'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    static currentCurrency() {
        return CookieFunctions.getCookieValue(CURRENCY_COOKIE) || CookieFunctions.getCookieValue(CURRENCY_DEFAULT_COOKIE);
    }

}
export default CookieFunctions;