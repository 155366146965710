import React, {useEffect} from "react";
import {render as reactDomRender} from "react-dom";
import SubscriptionActionHOC from "./subscriptionActionHOC";

import ModalForm from './form';
const SubscriptionForm = SubscriptionActionHOC(ModalForm);

export default function SubscribeButton(props) {

    const modalId ='subscriptionModal';

    const modalDivAttrs = {className: `reveal subscriptionReveal subscriptionReveal__img  add-animation` };
    modalDivAttrs['data-close-on-click'] = "false";
    modalDivAttrs['data-close-on-esc'] = "false";
    modalDivAttrs['aria-hidden'] = "false";
    modalDivAttrs['role'] = 'dialog';
    modalDivAttrs['data-animation-in'] = "slide-in-down fast";

    const openModal = () => {
        renderModal();
    };

    useEffect(
        () => {
            if (props.design === 'invisible' && !!props.listenToId) {
                const listenToElement = document.querySelector(props.listenToId);
                if (listenToElement) { listenToElement.addEventListener('click', openModal); }
            }
            return () => {
                let portalElement = document.getElementById(modalId);
                if (portalElement)
                    document.body.removeChild(portalElement);
                if (props.design === 'invisible' && !!props.listenToId) {
                    const listenToElement = document.querySelector(props.listenToId);
                    if (listenToElement) { listenToElement.removeEventListener('click', openModal); }
                }
            };
        },[]
    );

    const closeModal = () => {
        let portalElement = document.getElementById(modalId);
        reactDomRender(null, portalElement);
        document.body.removeChild(portalElement);
        document.documentElement.classList.remove('is-reveal-open');
    };

    let modalContent =  <div className="reveal-overlay" style={{display: 'block'}}>
        <div {...modalDivAttrs} style={{display: 'block'}}>
            <SubscriptionForm formType={props.formType} />
            <div className={"close-circle close-circle__blue-fill"} onClick={closeModal}>
                <span className="top"/>
                <span className="bottom"/>
            </div>
        </div>
    </div>;

    const renderModal = () => {
        if(document.getElementById(modalId))
            return;
        let p = document.createElement('div');
        p.id = modalId;
        document.body.appendChild(p);

        reactDomRender(modalContent, p);
        document.documentElement.classList.add('is-reveal-open');
    };

    const renderForm = () => {
        switch (props.design) {
            case 'top_notification':
                return (<React.Fragment>
                    {props.text?
                        <span className="top-promo__link" onClick={openModal} dangerouslySetInnerHTML={{__html: props.text}}/> :
                        <span className="top-promo__link" onClick={openModal}>SUBSCRIBE</span>
                    }
                    <span className="top-promo__arrow" onClick={openModal}/>
                </React.Fragment>);
            case 'ad_button':
                return  (
                    <button className="subscription__hunter--button" onClick={openModal}>
                        Subscribe
                    </button>
                );
            case 'sideNavigationMenu':
                return <a onClick={(e) => {e.preventDefault(); openModal()}}>SIGN UP <span className="small-icon newsletter mar-l-10"/></a>;
            case 'invisible':
                return null;
            default:
                return (<div className={`subscribe`}>
                    <button className="button-orange" onClick={openModal}>Subscribe</button>
                </div>);
        }
    };


    return (renderForm())

}
