import React from 'react';
import SearchBox from '../search/menuBox';
import SubscribeHandler from '../subscriptions/handler';

class Navigation extends React.Component {

    constructor(props) {
        super(props);
        let links = [];
        if (!!this.props.links)
            this.props.links.map((cat) => links.push({...cat, isExpanded: false}));

        this.state = {
            links: links,
            isExpanded: false
        };

        this.toggleMenu = document.querySelector('#toggle-menu');
        this.toggleMenuScroll = document.querySelector('#toggle-menu-scroll');
        this.overlayMenu = document.querySelector('.overlay-menu-container');
        this.bodyElement = document.querySelector('body');
    }

    componentDidMount() {
        if (this.toggleMenu) {
            this.toggleMenu.addEventListener('click', this.onClick,false);
        }
        if (this.toggleMenuScroll) {
            this.toggleMenuScroll.addEventListener('click', this.onClick,false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    open = () => {
        this.toggleMenu.classList.add('active');
        this.bodyElement.classList.add('noscroll');
        this.bodyElement.classList.add('active-overlay');
        var windowScrollY = window.scrollY;
        if (windowScrollY > 0) {
            this.overlayMenu.classList.add('scrolled-down');
        } else {
            this.overlayMenu.classList.remove('scrolled-down');
        }
        window.addEventListener('resize', this.onResize);
    };
    
    close = () => {
        this.toggleMenu.classList.remove('active');
        this.bodyElement.classList.remove('noscroll');
        this.bodyElement.classList.remove('active-overlay');
        window.removeEventListener('resize', this.onResize);
    };
    
    onClick = () => {
        if (this.bodyElement.classList.contains('active-overlay')) {
            this.close();
        } else {
            this.open();
        }  
    };
    
    onResize = () => {
        if (window.matchMedia('(min-width: 1025px)').matches && this.bodyElement.classList.contains('active-overlay')) {
            this.close();
        }
    };

    onExpandClick = (e, category) => {
        e.preventDefault();
        let links = this.expandUpdate(this.state.links, category);
        this.setState({links: links});
    };

    expandUpdate = (links, category) => {
        return links.map((cat) => {
            if (cat.id === category.id) {
                return {...cat, isExpanded: !category.isExpanded};
            } else if (cat.children.length > 0) {
                return {...cat, children: this.expandUpdate(cat.children, category)};
            }
            return {...cat, isExpanded: false}
        });
    };

    renderMenuLink = (link, key, wrappingTag) => {
        const MainTag = wrappingTag;
        if (link.children.length === 0) {
            let linkOptions = {className: link.button ? 'btn-item' : ''};
            if (link.blank) { linkOptions['target'] = '_blank' }
            return (
                <MainTag className={'menu-accordion__item'} key={key}>
                    <a href={link.url} {...linkOptions}>
                        {link.icon ? <span className={`menu-icn menu-icn--${link.icon}`}/> : null}
                        {link.name.toLocaleUpperCase()}
                        {link.button ? <span className="small-icon primary-arrow-right mar-l-10"></span> : null}
                    </a>
                </MainTag>
            )
        }
        return (
            <MainTag className={'menu-accordion__item'} key={key}>
                <a className={`${link.isExpanded ? 'active' : ''}`}
                   onClick={(e) => {this.onExpandClick(e, link)}}>
                    {link.icon ? <span className={`menu-icn menu-icn--${link.icon}`}/> : null}
                    {link.name.toLocaleUpperCase()} <span className={'arrow'}/>
                </a>
                <div className={`menu-accordion ${link.isExpanded ? 'expanded' : ''}`}>
                    {link.children.map((subLink, i) => { return this.renderMenuLink(subLink, `${key}_${i}`, 'div'); })}
                </div>
            </MainTag>
        )
    };

    renderTopSection = () => {
        return (<div className="overlay-search-block">
            <div className={'relative'}>
                <a href={'/'}><img src="/images/ca-white-full.svg" alt="CareerAddict Logo" className="overlay-logo"/></a>
                <div className="hamburger-icon active" onClick={this.close}>
                    <span className="top"></span>
                    <span className="middle"></span>
                    <span className="bottom"></span>
                </div>
            </div>
        </div>)
    };

    render() {
        return (
            <div className="overlay-menu" id="overlay-menu">
                <div className="overlay-wrapper">
                    {this.renderTopSection()}
                    <div className="overlay-search-block">
                        <SearchBox forOverlayMenu={true} />
                    </div>
                    <nav className="menu">
                        <ul>
                            {this.state.links.map((link, i) => { return this.renderMenuLink(link, `link_${i}`, 'li') })}
                            <div className="overlay-line"></div>
                            <li><SubscribeHandler formType={'top_menu'} design={'sideNavigationMenu'} /></li>
                            <div className="overlay-line"></div>
                            <a href="/about" className="overlay-link">ABOUT US</a>
                            <a href="/contact" className="overlay-link">CONTACT</a>
                            <a href="/editorial" className="overlay-link">EDITORIAL POLICY</a>
                            <li className="social-icons">
                                <a href='https://www.facebook.com/CareerAddict/' target='_blank'>
                                    <div className={'small-icon facebook-white-bg'}/>
                                </a>
                                <a href='https://www.youtube.com/CareerAddictVideo' target='_blank'>
                                    <div className={'small-icon youtube-white-bg'}/>
                                </a>
                                <a href='https://twitter.com/careeraddict' target='_blank'>
                                    <div className={'small-icon twitter-white-bg'}/>
                                </a>
                                <a href='https://www.linkedin.com/company/career-addict/' target='_blank'>
                                    <div className={'small-icon linkedIn-white-bg'}/>
                                </a>
                                <a href='https://www.instagram.com/careeraddict/' target='_blank'>
                                    <div className={'small-icon instagram-white-bg'}/>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div style={{height: '70px'}}></div>
                </div>
            </div>

        );
    }
}

export default Navigation;