import React from "react";
import {InputTypeField, actions} from "react-fields";


export default function Footer(props) {

    const form = props.form;

    const handleFieldChange = (e) => {
        let newForm = {...form};
        let field = e.target.name;
        newForm[field] = e.target.value;
        newForm['errors'][actions.camelize(field)] = "";
        props.setForm(newForm);
    };

    return (<div className={`subscribe input-with-button`}>
        <InputTypeField type='email' name="email" value={form.email}
                        onChange={handleFieldChange}
                        onKeyPress={props.submitOnEnter} errorMessage={form.errors.email}
                        placeholder="Your email" />
        <button className="button-orange" onClick={props.submitForm}>Sign Up <span className="small-icon arrow-white-right-small"></span></button>
    </div>)
}
