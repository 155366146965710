import {actions} from 'react-fields'
import 'whatwg-fetch';

class UserApi {

    static get(onLoad, notFound) {
        const url = `/api/users`;
        fetch(actions.getData(url))
            .then((response) => {
                if (response.status !== 201 && response.status >= 200 && response.status < 300) {
                    return Promise.resolve(response)
                } else {
                    return Promise.reject("Unauthorized")
                }
            }).then(actions.json)
            .then(function(data) {
                return data;
            }).then(  (response) => {
               onLoad(response);
        }).catch(error => {
            console.log(error);
            notFound();
        })
    }


    static save(user, onSuccess, onError) {
        const url = '/api/user';
        let data = { user: {
                bio: user.bio,
                linkedin_acc: user.linkedin_acc,
                twitter_acc: user.twitter_acc,
                facebook_acc: user.facebook_acc,
                website: user.website,
                first_name: user.first_name,
                last_name: user.last_name,
                receive_article_comments: user.receive_article_comments

            } };

        fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }


}

export default UserApi;