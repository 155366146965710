import React from 'react';
import PopUp from '../modals/popup';
import SearchBox from '../search/menuBox';

class Search extends React.Component {

    constructor(props) {
        super(props);

        this.searchPopUp = React.createRef();
        this.searchBox = React.createRef();
    }


    render() {
        return (
            <React.Fragment>
                <div className="search-icon" onClick={() => {this.searchPopUp.current.open(); this.searchBox.current.setFocus();}}>
                    <div className="magnify"/>
                </div>
                <PopUp ref={this.searchPopUp} className='full without-overlay' setNewZindex>
                    <div className="overlay-search open">
                        <div className="close-icon" onClick={() => {this.searchPopUp.current.close()}}>
                            <div className="small-icon white-cross"/>
                        </div>
                        <div className="search">
                            <ul>
                                <li>
                                    <div className='input-group search'><SearchBox ref={this.searchBox} /></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </PopUp>
            </React.Fragment>
        );
    }
}

export default Search;