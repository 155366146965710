import React, {Fragment} from 'react';
import {actions, TextField, InputTypeField} from "react-fields";
import LogoDesktop from "../../../../public/images/newsletter/logo-dose-desktop.png";
import LogoTablet from "../../../../public/images/newsletter/logo-dose-tablet.png";
import LogoMobile from "../../../../public/images/newsletter/logo-dose-mobile.png";
import Img from "../../../../public/images/home_page/newsletter-desktop-mobile.png";

export default function Form(props) {

    const form = props.form;

    const handleFieldChange = (e) => {
        let newForm = {...form};
        let field = e.target.name;
        newForm[field] = field === 'terms_agreement' ? e.target.checked : e.target.value;
        newForm['errors'][actions.camelize(field)] = "";
        props.setForm(newForm);
    };


    return (
        <Fragment>
            <div className="grid-x">
                <div className="large-6 medium-12 small-12 cell sub-left-block">
                    <img src={LogoDesktop} width="139" height="40"  alt="Newsletter Logo" className={'show-for-large'} />
                    <img src={LogoTablet} width="152" height="44"  alt="Newsletter Logo" className={'show-for-medium-only'} />
                    <img src={LogoMobile} width="115" height="33"  alt="Newsletter Logo" className={'show-for-small-only'} />
                    <p className="sub-title">CAREER FUEL</p>
                    <p className={"sub-subtitle"}>DIRECT TO YOUR INBOX</p>
                    <p className="sub-text">
                        ⚡ Subscribe to CareerAddict's <a href="https://dose.careeraddict.com/" className="underlined" target={'_blank'}>THE<b>DOSE</b></a> for productivity hacks, career inspo, exclusive advice, giveaways, plus more <b>— for FREE!</b>
                    </p>
                    <div className={(form.errors.email || form.errors.name) ? 'subscribe-input-with-button err' : 'subscribe-input-with-button'}>
                        <InputTypeField type='email' name="email" value={form.email} onChange={handleFieldChange}
                                        className={'input-with-container'}
                                        placeholder="Your email" errorMessage={form.errors.email} />

                         <button  onClick={props.submitForm}  className="button-orange newsletter-article-sub">SIGN UP <span className="white-right-arrow"/></button>
                    </div>
                    <div className="subscription-cv-download__desc subscription-cv-download__desc--primary">
                        2 mails per week. Unsubscribe ANY TIME.
                    </div>
                </div>
                <div className="large-auto medium-12 small-12 cell sub-right-block">
                    <div className="img-placeholder">
                        <img src={Img} alt="Newsletter Logo" />
                    </div>
                </div>
            </div>

        </Fragment>
    )
}