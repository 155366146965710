import React, {useRef, useEffect, useState} from 'react';
import PopupsApi from "../api/popupsApi";
import {parseCookies, setCookie} from "../base/cookieHelper";

const Modal = (props) => {
    const [customTopClass, setCustomTopClass] = useState('');

    useEffect(() => {
        let windowH = window.innerHeight;
        let modalH = modalRef.current.offsetHeight;
        if(modalH > windowH) {
            setCustomTopClass(' stick-on-top')
        }
    }, [ ]);

    const modalRef = useRef(null);

    let modalDivAttrs = {className: `reveal ${props.modalName || ''}${customTopClass} add-animation`};
    modalDivAttrs['data-close-on-click'] = "false";
    modalDivAttrs['data-close-on-esc'] = "false";
    modalDivAttrs['aria-hidden'] = "false";
    modalDivAttrs['role'] = 'dialog';
    modalDivAttrs['data-animation-in'] = "slide-in-down fast";

    const setupCustomCloseIcon = () => {
        switch(props.closeIcon) {
            case 'circle_white':
                return ' close-circle__white';
            case 'white_fill':
                return ' close-circle__white-fill';
            case 'blue_fill':
                return ' close-circle__blue-fill';
            default:
                return '';
        }
    };

    const onClick = (e) => {
        try {
            let clickedPopups = parseCookies('ca_pop_c', []);
            if (!!props.clickTracking && clickedPopups.indexOf(props.clickTracking.id) < 0 && e.target.matches('a, a *, button:not(.close-button), button:not(.close-button) *')) {
                clickedPopups.push(props.clickTracking.id);
                setCookie('ca_pop_c', clickedPopups);
                PopupsApi.trackClick(props.clickTracking.id)
            }
        } catch ({ name, message }) {
            console.log(name); // "TypeError"
            console.log(message); // "oops"
        }
    };


    if (!!props.clickTracking) {
        modalDivAttrs['onClick'] = onClick;
    }

    return (
        <div className="reveal-overlay" style={{display: 'block'}} >
            <div {...modalDivAttrs} style={{display: 'block'}} ref={modalRef}>
               {props.children}
                {props.closeIcon && props.closeIcon !== "standard"  ?
                     (
                         <React.Fragment>
                             <style>
                                 {`.close-button {display: none;}`}
                             </style>
                             <div className={"close-circle" + setupCustomCloseIcon()} onClick={props.close}>
                                 <span className="top"/>
                                 <span className="bottom"/>
                             </div>
                         </React.Fragment>

                    ) : (
                    <button className="close-button big" aria-label="Close modal" type="button" onClick={props.close}>
                    <div aria-hidden="true"/>
                    </button>
                    )
                }

            </div>
        </div>
    );

};

export default Modal;
