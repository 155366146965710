import React from 'react';
import UserApi from '../api/user';

class UserIcon extends React.Component {
    state = {
        user: null
    };

    componentDidMount() {
        UserApi.get(this.onLoadUser, this.resetUser)
    }

    onLoadUser = (user) => {
        this.setState({user: user});
    };

    resetUser = () => {
        this.setState({user: null});
    };

    render () {
        if (!this.state.user){
          return null;
        }
        return (
                <a href='/account/profile' className='account-icon' aria-label="User Account">
                  <div className="account"/>
                </a>
        )
    }
}
export default UserIcon;
