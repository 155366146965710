import React, {useState, useRef} from 'react';

import CA_logo from "../../../../../assets/images/CA-Logo-Colour.svg";
import EmailForm from './emailForm';

import BookmarkModal from './bookmarkMobile';
import {isDevice} from "../../../utils";

export default function CheckoutExitPopupContent(props) {
    const [isEmailFormOpened, setEmailFormOpened] = useState(false);
    const mobileBookmarkMsg = useRef(null);

    const bookmark = () => {
        if (isDevice()) {
            mobileBookmarkMsg.current.open();
        } else
        if (window.sidebar && window.sidebar.addPanel) { // Mozilla Firefox Bookmark
            window.sidebar.addPanel(document.title, window.location.href, '');
        } else if (window.external && ('AddFavorite' in window.external)) { // IE Favorite
            window.external.AddFavorite(location.href, document.title);
        } else if (window.opera && window.print) { // Opera Hotlist
            this.title = document.title;
            return true;
        } else { // webkit - safari/chrome
            alert('Press ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Command/Cmd' : 'CTRL') + ' + D to bookmark this page.');
        }
    };

    return (
        <div className="grid-x">
            <div className="small-12 cell text-center">
                <img src={CA_logo} alt={'Career Addict'} height="22" width="180"/>
                <h3>Not quite ready to checkout?</h3>
                <div className="cv-checkout-popup-msg">Share or save and return later:</div>

                <div className="grid-x share-function-box">
                    <div className="small-8 cell"><p className="share-function-text">Share to your email</p></div>
                    <div className="small-4 cell flex-end">
                        <a className='button button-grey-share' onClick={() => setEmailFormOpened(true)}>
                            <span className="cv-bundle-icon blue-email"></span>
                        </a>
                    </div>
                    {isEmailFormOpened ? <EmailForm /> : null}
                </div>

                <div className="grid-x share-function-box">
                    <div className="small-8 cell"><p className="share-function-text">Bookmark page</p></div>
                    <div className="small-4 cell flex-end">
                        <a className='button button-grey-share' onClick={bookmark}>
                            <span className="cv-bundle-icon blue-star"></span>
                        </a>
                    </div>
                </div>

                <div className="grid-x share-function-box">
                    <div className="small-8 cell"><p className="share-function-text">Print product details</p></div>
                    <div className="small-4 cell flex-end">
                        <a className='button button-grey-share' href={'/checkout/download-pdf'} target={'_blank'}>
                            <span className="cv-bundle-icon blue-print"></span>
                        </a>
                    </div>
                </div>

                <a className="button button-red" onClick={props.close}>Return to checkout</a>
                <p className="cv-checkout-popup-note"><span>Have an issue?</span> We would love to get your opinion, <a href={'/contact'}> get in touch</a>.</p>
            </div>
            <BookmarkModal ref={mobileBookmarkMsg} />
        </div>
    );

};
