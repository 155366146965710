import React from 'react';
import {InputTypeField, TextField, ErrorTooltip, actions} from "react-fields";
import TermsCheckboxField from '../../customFields/termsCheckbox';


export default function TemplateDownloadButton(props) {

    const handleFieldChange =(e) => {
        let form = {...props.form};
        let field = e.target.name;
        form[field] = e.target.value;
        form['errors'][actions.camelize(field)] = "";
        props.setForm(form);
    };

    return (
        <div>
            <div className={(props.form.errors.email || props.form.errors.name) ? 'subscribe-input-with-button err' : 'subscribe-input-with-button'}>
                <InputTypeField type='email' name="email" value={props.form.email} onChange={handleFieldChange}
                                className={'input-with-container brd-radius-25 text-center'}
                                placeholder="Your email" errorMessage={props.form.errors.email} />

                <button  onClick={props.submitForm}  className="button-orange newsletter-article-sub width-100">DOWNLOAD NOW <span className="white-right-arrow"/></button>
            </div>
        </div>
    )

}