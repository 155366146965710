import React, {Fragment} from 'react';

import TermsCheckboxField from "../../../customFields/termsCheckbox";
import {actions, InputTypeField, Loader} from "react-fields";
import InvisibleRecaptcha from "../../../base/invisibleRecaptcha";
import CaptchaSettings from '../../../captchaSettings';

import shareApi from '../../../api/shareAPI';

const DEFAULT_FORM = {email: '', agreeTerms: false, errors: {}};

export default class ExitPopupEmailForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {form: {...DEFAULT_FORM}};

        this.invisibleCaptcha = React.createRef();
    }

    onChange = (e) => {
        let updatedForm = {...this.state.form};

        let name = e.target.name;
        updatedForm[name] = name === 'agreeTerms' ? e.target.checked : e.target.value;
        updatedForm.errors[name] = "";
        this.setState({form: updatedForm});
    };

    onSend = (e) => {
        e.preventDefault();
        try {
            if (!CaptchaSettings.site_key) {
                console.log('captcha disabled');
                this.send('token');
            } else if (window.grecaptcha) {
                this.invisibleCaptcha.current.execute();
            }
        } catch (error) {
            console.warn('something is wrong with invisible captcha....');
        }
    };

    send = (token) => {
        shareApi.send(this.state.form, token, this.onSuccess, this.onError);
        if (CaptchaSettings.site_key) { this.invisibleCaptcha.current.reset(); }
    };

    onSuccess  = () => {
        const subject = encodeURIComponent('CareerAddict CV Templates');
        const body = encodeURIComponent('Hi,\n' +
            '\n' +
            'I’m just sharing CareerAddict’s CV template bundle with you.\n' +
            '\n' +
            'http://ca.findjobs.eu/cv-templates/bundle/best\n' + '\n');
        const link = `mailto:${this.state.form.email}?subject=${subject}&body=${body}`;
        //window.open(link);
        window.location.href = link;
    };

    onError  = (errors) => {
        let updatedForm = {...this.state.form};
        updatedForm.errors = errors;
        this.setState({form: updatedForm});
    };

    render() {
        const form = this.state.form;
        return (
            <div className={'grid-x share-function-box form-container'}>
                <div className={`small-12 cell align-left ${!!form.errors.agreeTerms ? 'termsError' : ''}`}>
                    <TermsCheckboxField name="agreeTerms" value={form.agreeTerms}
                                        errorMessage={form.errors.agreeTerms}
                                        onChange={this.onChange}/>
                </div>

                <div className="small-12 cell share-email-input">
                    <InputTypeField type={"email"} name={"email"}
                                    placeholder={"Enter email"}
                                    errorMessage={form.errors.email}
                                    value={form.email}
                                    onChange={this.onChange}/>
                    <a className='button button-grey-share' onClick={this.onSend}>Send</a>
                </div>


                <InvisibleRecaptcha ref={this.invisibleCaptcha}
                                    sitekey={CaptchaSettings.site_key}
                                    verifyCallback={this.send}/>
            </div>
        );
    }

};
