import React from 'react';
import {actions, Loader} from "react-fields";
import SubscriptionApi from "../api/subscription";
import InvisibleRecaptcha from "../base/invisibleRecaptcha";

import CaptchaSettings from '../captchaSettings';
import cookies from "../cookies";

import PopUp from '../modals/popup';
import ReferralContent from "./referralModalContent";

const SubscriptionActionHOC = (PassedComponent) => {
    return class extends React.Component {

        state = {form: {
                encrypted_key: this.props.encrypted_key || null,
                email: this.props.email || '',
                name: this.props.name || '',
                terms_agreement:  this.props.terms_agreement || false,
                errors: {},
                form_type: this.props.formType || 'article',
                newsletter_form_id: this.props.formId,
                popup_id: this.props.popupId,
                ad_banner_id: this.props.adBannerId,
            },
            isLoading: false,
            portalElement: null,
            isReferralPopUpOpen: false
        };

        referralModal = React.createRef();
        recaptchaInstance = React.createRef();
        windowOpener = null;

        setEmail = (e) => {
            let newForm = {...this.state.form};
            newForm['email'] = e.target.value;
            newForm['errors']['email'] = "";
            this.setForm(newForm);
        };

        setForm = (form) => {
          this.setState({form: form});
        };

        setErrors = (errors) => {
            console.log('setErrors', errors);
            this.recaptchaInstance.current.reset();
            this.setState({form: {...this.state.form, errors: errors}, isLoading: false});
        };

        sendForm = (token = null) => {
            let _this = this;
            this.setState({form: {...this.state.form, errors: {}}, isLoading: true}, () => {
                SubscriptionApi.validate(this.state.form, this.state.categories, () => {
                    SubscriptionApi.submit(this.state.form, this.state.categories, token).then((response) => {
                        console.log(response);
                        _this.setState({isLoading: false});
                        cookies.setCookie('ca_sub',  true);
                        cookies.setMainCookie('ca_sub_d',  response['digest']);
                        if(_this.props.afterSave)
                          _this.props.afterSave(response);
                        else if (response['open_url']) {
                            this.setState({form: {...this.state.form, email: '', errors: {}}, isLoading: false, referral_url: response['open_url'], giftName: response['gift_name']});
                            this.recaptchaInstance.current.reset();
                            if (_this.props.onReferralModalOpen) {
                                _this.props.onReferralModalOpen(response);
                            } else {
                                this.referralModal.current.open();
                            }
                        }
                        else {
                            document.location.href= response['redirect_to'] || '/subscriptions/thank-you';
                        }

                    }).catch(error => {
                        console.log(error);
                        error.json().then((value) => {
                            _this.setErrors(value);

                        });
                    });
                },  _this.setErrors);
            });
        };

       submitForm = (e) => {
           this.windowOpener = window;
            if (e)
                e.preventDefault();

            if(!CaptchaSettings.site_key){
                return this.sendForm();
            }
            try {
                this.recaptchaInstance.current.execute();
            } catch (error) {
                console.log('captcha err');
                console.log(error);
            }
        };

        submitOnEnter = (e) => {
            if(e.key === 'Enter'){
                this.submitForm();
            }
        };

        onClickReferral = (e) => {
            window.open(this.state.referral_url, '_blank').focus();
            this.referralModal.current.close();
        };

        render(){
            return (
                <form style={{position: 'relative'}}>
                    {this.state.isLoading ? <Loader/> : null}
                    <InvisibleRecaptcha ref={this.recaptchaInstance} sitekey={CaptchaSettings.site_key}
                                        verifyCallback={this.sendForm} />
                    <PassedComponent form={this.state.form} formAttrs={this.props}
                                     setForm={this.setForm} setEmail={this.setEmail}
                                     submitForm={this.submitForm}
                                     categories={this.state.categories}
                                     unsubscribe={this.props.unsubscribe}
                    />

                    <PopUp ref={this.referralModal} className={'flexible-popup-base cvTemplateDownload add-animation'} isSubscriptionReferral={true} >
                        <ReferralContent onClick={this.onClickReferral} giftName={this.state.giftName || 'gift'} close={() => this.referralModal.current.close()} />
                    </PopUp>
                </form>
            )
        }
    }

};
export default SubscriptionActionHOC;