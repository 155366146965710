import {actions} from 'react-fields'

class TopNotificationssApi {

    static getLayers(path) {
        let url = `/api/top_notifications?path=${encodeURIComponent(path)}`;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then((data) => {
                return data;
            })
    }



}

export default TopNotificationssApi;