import React, {useState} from "react";
import Img from "../../../../../public/images/popups/cvCouponSubscribe/CV-Writing_Subscribe.png";
import Form from '../../subscriptions/forms/inlineBigButton';
import SubscriptionActionHOC from '../../subscriptions/subscriptionActionHOC';

const SubscriptionForm = SubscriptionActionHOC(Form);

function SubscriptionCv(props) {

    return(
        <div className="grid-x align-center">
            <div className="cell medium-7 small-12 small-order-2 medium-order-1 popup-wrapper">
                <div className="vertical-line-orange">
                    <div className="horizontal-line-orange"/>
                    <h2 className="popup-heading blue">Claim your 10% discount!</h2>
                    <p className="popup-coupon-description">
                        Sign up to our weekly newsletter and get <span>10% OFF</span> on any Résumé Writing package.
                    </p>
                    <SubscriptionForm formType={'popup_cv'} popupId={props.popupId} />
                </div>
            </div>
            <div className="cell medium-5 small-order-1 medium-order-2 small-12 text-center popup-right-area">
                <div style={{background: `url(${Img})`, backgroundPosition: 'right center',
                    backgroundSize: 'contain', backgroundRepeat: 'no-repeat', width: '305px', height: '315px'}} className="popup-cv-sub-img" />
            </div>
        </div>
    );

}
export default SubscriptionCv;