export function isDevice() {
    const arg = (navigator.userAgent||navigator.vendor||window.opera);
    return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(arg) ||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(arg.substr(0,4)));
}

export function makeBarSticky(anchor, container) {
    const anchorHeight = anchor.getBoundingClientRect().height;
    const mainArticleOffsetTop = document.querySelector('#article-main').getBoundingClientRect().top + window.scrollY;
    const mainArticleClientHeight = document.querySelector('#article-main').clientHeight;
    const stickyTopBarHeight = document.querySelector('.sticky-topbar').clientHeight;
    const mainBody =  document.querySelector('.article-main-center');

    const stopTop = mainArticleOffsetTop - stickyTopBarHeight;

    // mainArticleClientHeight + mainArticleOffsetTop = end of #article-main
    // 158 - ((window.innerHeight)/2 - 265) = position of anchor element
    const stopBottom = mainArticleClientHeight + mainArticleOffsetTop - 158 - ((window.innerHeight)/2 - 265) - anchorHeight;

    container.style.height = anchorHeight + 'px !important';
    function setSticky(anchor) {
        anchor.classList.add('is-stuck');
        anchor.style.marginTop = '158px';
    }
    function removeSticky(anchor) {
        anchor.classList.remove('is-stuck');
        anchor.style.marginTop = '0';
    }
    function setAtTop(anchor) {
        anchor.classList.remove('is-at-bottom', 'is-anchored');
        anchor.classList.add('is-at-top');
        anchor.style.top = '0';
    }
    function setAtBottom(anchor) {
        anchor.classList.remove('is-at-top');
        anchor.classList.add('is-at-bottom', 'is-anchored');
        anchor.style.top = (mainArticleClientHeight + document.querySelector('#article-main').offsetTop - anchorHeight) + 'px'; // (end of #article_main relatively to .article-view.relative) - anchorHeight
    }
    if (window.scrollY >= 300) {
       // if (window.scrollY <= stopBottom ) {
            setSticky(anchor);
            setAtTop(anchor);
       // } else {
        //    removeSticky(anchor);
        //    setAtBottom(anchor);
        }
     else {
        removeSticky(anchor);
    }
}

export function numberToHuman(n, digits=1) {
    const ranges = [
        // { divider: 1e18 , suffix: 'E' },
        // { divider: 1e15 , suffix: 'P' },
        // { divider: 1e12 , suffix: 'T' },
        // { divider: 1e9 , suffix: 'G' },
        { divider: 1e6 , suffix: 'M' },
        { divider: 1e3 , suffix: 'k' }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    for (let i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
            return (n / ranges[i].divider).toFixed(digits).replace(rx, "$1") + ranges[i].suffix;
        }
    }
    return n.toString();
}

export function updateMeta(title, desc) {
    let metaTitle = document.getElementsByTagName("title");
    metaTitle[0].textContent = title;
    let metaOgTitle = document.querySelector("meta[property='og:title']");
    metaOgTitle.setAttribute("content",title);
    let metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute("content", desc);
    let metaOgDescription = document.querySelector("meta[property='og:description']");
    metaOgDescription.setAttribute("content",desc);
}