import React from "react";
import {ErrorTooltip, ErrorMessage} from 'react-fields'

class TermsCheckboxField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            className: this.props.className || "",
            position: this.props.position || 'left'
        }
    }

    render() {
        const ErrorComp = this.props.errorTypeMessage ? ErrorMessage : ErrorTooltip;

        return (
            <div className={"relative-checkbox " + this.state.className + (this.props.errorMessage ? ' err' : '')}>
                <label className={'relative-checkbox__label'} >
                    <input
                        type="checkbox"
                        value={this.props.value || false}
                        onChange={this.props.onChange}
                        name={this.props.name}
                        checked={this.props.value || false}
                        disabled={this.props.disabled || false}
                        className="custom-checkbox-input"
                    />
                    <div className={"custom-checkbox" + (this.props.value ? ' checked' : '') + (this.props.disabled ? ' disabled' : '')}>
                        <svg width="12px" height="10px" viewBox="0 0 12 10">
                            <polyline points="1.5 6 4.5 9 10.5 1"/>
                        </svg>
                    </div>
                    <span className="agreeText">I agree to the <a href="/terms" className="secondary-link" target='_blank'>Terms</a> and <a href="/privacy"
                                                                                                            className="secondary-link" target='_blank'>Privacy</a></span>
                </label>
                {this.props.errorMessage ? <ErrorComp message={this.props.errorMessage}/> : null}

            </div>
        );

    }
}
export default TermsCheckboxField;