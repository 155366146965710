import React from 'react';
import {render as reactDomRender} from "react-dom";
import '../../../../../assets/stylesheets/bookmark_msg.scss.erb';


export default class BookmarkMobile extends React.Component {

    state = {
        debugOs: null, debugBrowser: null,
        isOpened: false,
        lifespan: 15 // life of the message in seconds
    };

    componentWillUnmount() {
        if (this.portalElement)
            document.body.removeChild(this.portalElement);
        if (this.removeTimer)
            clearTimeout(this.removeTimer);
    }

    close = () => {
        if (!this.portalElement) { return; }
        if (this.props.onClose) { this.props.onClose(); }
        if (this.portalElement) {
            reactDomRender(null, this.portalElement);
            document.body.removeChild(this.portalElement);
            this.portalElement = null;
        }
        this.setState({isOpened: false});
    };


    open = () => {
        if (this.state.isOpened) {return;}

        let p = document.createElement('div');
        p.id = 'bookmarkModal';
        document.body.appendChild(p);
        this.portalElement = p;
        
        const  _ua = window.navigator.userAgent;
        const isIDevice = (/iphone|ipod|ipad/i).test(_ua);

        const isMobileSafari = isIDevice && _ua.indexOf('Safari') > -1 && _ua.indexOf('CriOS') < 0;
        const isMobileChrome = _ua.indexOf('Android') > -1 && (/Chrome\/[.0-9]*/).test(_ua) && _ua.indexOf("Version") == -1;
        const isMobileIE = _ua.indexOf('Windows Phone') > -1;

        let os = isIDevice ? 'ios' : (isMobileIE ? 'windows' : 'android');
        let osVersion = _ua.match(/(OS|Android) (\d+[_\.]\d+)/);
        const isTablet = (isMobileSafari && _ua.indexOf('iPad') > -1) || (isMobileChrome && _ua.indexOf('Mobile') < 0);

        let browser = 'default';
        let label = 'Bookmark';
        let isSafari = navigator.vendor.match(/apple/i) &&
            !navigator.userAgent.match(/crios/i) &&
            !navigator.userAgent.match(/fxios/i);
        if (isSafari) {
            browser = 'safari';
            label = 'Add to Favourites';
        }

        // setup the debug environment
        if ( this.state.debugOs ) {
            os = typeof this.state.debugOs == 'string' ? this.state.debugOs : os;
            browser = typeof this.state.debugBrowser == 'string' ? this.state.debugBrowser : os;
            osVersion = (os === 'ios' ? '8' : '4');
        }

        const classNameStr = `ath-container ath-${os} ath-${os}${parseInt(osVersion) || ''} ath-${browser} ath-${(isTablet ? 'tablet' : 'phone')}`;
        reactDomRender(<div className="ath-viewport" onClick={this.close}>
            <div className={classNameStr}>
                    <p>To add this web app to the home screen: tap <span className="ath-action-icon">icon</span> and then <strong>{label}</strong>.</p>
            </div>
        </div>, this.portalElement);

        this.setState({isOpened: true});
        this.removeTimer = setTimeout(this.close, this.state.lifespan * 1000);
    };

    render() {
        return null;
    }

};
