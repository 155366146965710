import React, { Component } from 'react';
import {render as reactDomRender} from "react-dom";

class PopUp extends Component {

    state = {id: Math.random().toString(36).substring(7)};

    open = () => {
      this.renderModal();
    };

    close = () => {
        if (!this.portalElement) { return; }
        if (this.props.onClose) { this.props.onClose(); }
        reactDomRender(null, this.portalElement);
        document.body.removeChild(this.portalElement);
        this.portalElement = null;
        if (this.props.disablePageScroll) {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
        if (this.props.isSubscriptionReferral || this.props.disableOuterPageScroll) {
            document.documentElement.classList.remove('is-reveal-open');
        }
    };

    componentWillUnmount() {
        if (this.portalElement)
            document.body.removeChild(this.portalElement);
    }

    renderModal = () => {
        let p = document.createElement('div');
        p.id = this.state.id;
        document.body.appendChild(p);
        this.portalElement = p;

        if (this.props.disablePageScroll) {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
        }

        let style = {};
        if (this.props.isSubscriptionReferral || this.props.disableOuterPageScroll) {
            document.documentElement.classList.add('is-reveal-open');
            if (this.props.isSubscriptionReferral) {style['display'] = 'block';}
        }

        let overlayClasses = ['reveal-overlay'];
        if (!this.props.isSubscriptionReferral) { overlayClasses.push('reveal-popup'); }
        if (this.props.setNewZindex) { overlayClasses.push('reveal-search'); }
        if (!!this.props.overlayClass) { overlayClasses.push(this.props.overlayClass); }

        reactDomRender(<div className={overlayClasses.join(' ')} style={style}>
            <div className={`reveal ${this.props.className}`} style={style}>
                {this.props.children}
            </div>
        </div>, this.portalElement);
    };

    render () {
        return null
    }


}

export default PopUp;