import React from "react";
import Index from "./index";
import SideLayerApi from "../api/sideLayerApi";
import cookies from "../cookies";
import {setCookie} from "../base/cookieHelper";

class Show extends React.Component {

    state = {
        isOpened: false,
        showLayer: false
    };

    componentDidMount() {
        this.loadLayer(new Date);
    }


    loadLayer = (startTimer) => {
        let path = window.location.pathname;
        let _this = this;
        SideLayerApi.getLayer(path).then((response) => {
            if (response.side_layer) {
                _this.setState({showLayer: true});
                _this.loadRuleCheck(response.side_layer, startTimer)
            }
        }).catch(error => {
            console.log(error);
        });
    };




    loadRuleCheck = (layer, startTimer) => {
        let ca_side = cookies.getCookieValue('ca_side');

        if(ca_side && atob(ca_side) === 'closed') {
            return;
        }
        if (!layer.show_after_time) {
            this.setState({isOpened: true});
        } else {
            let showAfter = (parseFloat(layer.show_after_time) * 1000) - (new Date - startTimer);
            if (showAfter <= 0) {
                this.setState({isOpened: true});
            } else {
                window.setTimeout(() =>    this.setState({isOpened: true}),showAfter);
            }
        }
    };

    toggleMiniWindow = () => {
        const isMiniWindowHidden = this.state.isOpened;
        if(isMiniWindowHidden) {
           setCookie('ca_side',  'closed');
        }
        this.setState({
            isOpened: !isMiniWindowHidden
        })
    };


    render() {
        return (
            this.state.showLayer && <Index isOpened={this.state.isOpened} toggleMiniWindow={this.toggleMiniWindow}/>

        );
    }




};

export default Show;