import React from "react";

class MenuBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {q: ""};
        this.searchInput = React.createRef();
    }

    setFocus = () => {
        setTimeout(() =>{
            this.searchInput.current.focus();
        }, 500)

    };


    handleKeyWordChange = (e) => {
        let value = e.target.value;
        this.setState({q: value})
    };

    onPressEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            this.submitSearch()
        }
    };

    submitSearch = () => {
        let q = this.state.q ? `?q=${encodeURIComponent(this.state.q)}` : '';
       document.location.href = `/search${q}`

    };


    render(){
        if (this.props.forOverlayMenu) {
            return (
                <div className="input-search-field">
                    <input name="q"
                           autoComplete="off"
                           onChange={this.handleKeyWordChange}
                           placeholder="Search"
                           value={this.state.q}
                           onKeyPress={this.onPressEnter} />
                    <button onClick={this.submitSearch} className={(this.state.q && this.state.q.length > 0) ? 'active' : ''}>
                        <span className="small-icon small-magnify"/>
                    </button>
                </div>
            )
        }
        return(
            <React.Fragment>
                <div className="input-group-field">
                    <input     name="q"
                               id="searchInput"
                               autoComplete="off"
                               ref={this.searchInput}
                               onChange={this.handleKeyWordChange}
                               placeholder="Search"
                               value={this.state.q}
                               onKeyPress={this.onPressEnter} />
                </div>
                <span className="input-group-label" onClick={this.submitSearch}>
                     <div className="small-icon white-magnify"/>
                </span>
            </React.Fragment>
        )
  }
}
export default MenuBox;