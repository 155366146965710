import React from "react";
import helpIcon from '../../../assets/images/qre/i-HelpTool.svg';
import helpIconMobile from '../../../assets/images/qre/i-HelpTool-mobile.svg';
import miniHelpIcon from '../../../assets/images/qre/i-icon.svg';
import miniHelpIconMobile from '../../../assets/images/qre/i-icon-mobile.svg';
import caLogo from '../../../assets/images/qre/CA_QA_Logo_White.svg';
import caLogoMobile from '../../../assets/images/qre/CA_QA_Logo_White_Mobile.svg'

class Index extends React.Component{

    render(){
        return(
            this.props.isOpened ?
            <div className={'side-help-window '}>
                <div className="side-help-close" onClick={this.props.toggleMiniWindow}> </div>
                <img src={caLogo} alt={"Career Addict Logo"} width="113" height="14" className="hide-for-small-only side-help-logo"/>
                <img src={caLogoMobile} alt={"Career Addict Logo"} width="68" height="8"  className="show-for-small-only side-help-logo"/>
                <div className="side-help-circle">
                    <div className="side-img-wrapper">
                    <img src={helpIcon} alt={"Help"} width="46" height="46" className="hide-for-small-only"/>
                    <img src={helpIconMobile} alt={"Help"} width="28" height="28" className="show-for-small-only"/>
                    </div>
                </div>
                <p className="side-help-tool">HELP TOOL</p>
                <p className="side-help-info">Let us quickly guide you around our site.</p>
                <a className="button side-help-button" href={'/questionnaire'}>Get started<span className="small-icon orange-right-arrow"> </span></a>
            </div> :
                    <div className="side-help-closed"  onClick={this.props.toggleMiniWindow}>
                        <p className="side-help-info-vertical">Help</p>
                        <img src={miniHelpIcon} alt="help"  width="19" height="19" className="side-help-icon hide-for-small-only"/>
                        <img src={miniHelpIconMobile} alt="help"  width="15" height="15" className="side-help-icon show-for-small-only"/>
                    </div>

        )
    }
}
export default Index;